import * as React from "react"

import StaticImage from 'gatsby-plugin-image'
import Helmet from 'react-helmet'

import {
    Box,
    Container,
    Button,
    IconButton,
    Heading,
    Text,
    Select,
    Grid,
    GridItem,
    Card,
    Divider,
    Stack
} from "@chakra-ui/react";
import NewHeader from "../components/NewHeader.js";
import Footer from "../components/Footer.js";

const congestionBenefitText = [
    {
        title: "Better Driving",
        text: "NY-area drivers will spend a lot less time stuck in traffic. Cutting back just a fraction of car and truck trips speeds up everyone’s travel — drivers, truckers, taxis, Ubers, ambulances. Their time savings will total 300,000 hours a day, mostly in suburbs and boroughs outside Manhattan.",
        img: {
            src: "",
            alt: ""
        }
    },
    {
        title: "Better Transit",
        text: "Modern signals paid for with the congestion tolls will let subway lines handle 25 to 28 trains an hour — a huge boost in speed and reliability over the current 20 or less. Straphangers will spend 200,000 fewer hours a day waiting or standing in trains.",
        img: {
            src: "",
            alt: ""
        }
    },
    {
        title: "Better Access",
        text: "Less traffic and better transit means cleaner air, safer and quieter streets, and lower carbon emissions. It also means a city where jobs, medical care, shopping and cultural attractions are more accessible and open to all.",
        img: {
            src: "",
            alt: ""
        }
    },
    {
        title: "Fairness",
        text: "Yes, a new toll of $15 to drive into Manhattan south of 60th Street during peak times isn’t chump change. But that same trip now steals $100 worth of time from others. The highway robbery isn’t the new toll, it’s the gridlock that the toll will help break.",
        img: {
            src: "",
            alt: ""
        }
    },
    {
        title: "Congestion Pricing's Unique Power",
        text: "Congestion pricing is durable. The toll dissuades new auto trips that might otherwise replace the current ones that disappear. It will end 75 years of failed attempts to cut gridlock. It also requires no federal funds — another reason for Secretary Buttigieg to get out of the way.",
        img: {
            src: "",
            alt: ""
        }
    }
]

/*
<StaticImage
                src={img.src}
                alt={img.alt}
                layout="fixed"
                width="200px"
                height="200px"
            ></StaticImage>

*/

function BenefitCard( {title, text, img} ) {
    return (
        <Box p="5" shadow="md" borderWidth="1px" >
            
            <Heading fontSize="xl">
                {title}
            </Heading>
            <Text mt={4}>
                {text}
            </Text>
        </Box>
    )
}


const why = () => {
    return (
        <Box>
            <Helmet><title>Good Traffic NYC — Solution</title></Helmet>
            <NewHeader />
            <Box padding="20px">
                <Container>
                    <Heading
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                    >
                        Congestion Pricing is a plan for NYC's future.
                    </Heading>
                    <Text padding="10px 0 10px 0">
                        Charging drivers to enter Manhattan below 60th Street tackles two big NYC problems at once. It cuts
    traffic on crammed streets and jammed highways. And it funds vital improvements in public transit.
                    </Text>
                </Container>
                <Container padding="20px" boxShadow="sm" borderRadius="5">
                    <Stack spacing={5} direction="column">
                    {
                        (congestionBenefitText || []).map( (item) => (
                            <BenefitCard title={item.title} text={item.text} img={item.img}/>
                        ))
                    }
                    </Stack>
                </Container>
            </Box>
        <Footer />
        </Box>
        
    )
}

export default why;